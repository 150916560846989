<!-- eslint-disable -->
<template>
    <app-wrapper>
        <template v-slot:child>
            <div>
                <template v-if="!sale">
                    <Spinner />
                </template>
                <router-link v-if="back" :to="{ name: 'PosSalesHistory' }">
                    <div class="ml4">&lt; Back</div>
                </router-link>

                <div v-if="sale" class="flex flex-wrap justify-between items-center w-80-l w-90 center mt4">
                    <div class=""></div>
                    <div class="action-btns">
                        <router-link :to="{ name: 'Pos' }">
                            <button class="btn2">Record Sale</button>
                        </router-link>
                        <button class="ml2" @click.prevent="printReceipt">
                            <img :src="require('@/assets/images/printer.svg')" alt="" /> Print
                        </button>
                    </div>
                </div>
                <div id="receiptContainer" ref="posRef" class="box-border w-80-l w-90 center mt2 mini-spacing"
                    v-if="sale">
                    <div class="w-100 flex justify-between">
                        <div class="box-border-bottom b f4-l f5 pa3-l pa3" style="color: #132c8c">POS Receipt - {{
                            moment(sale.createdAt).format('ddd MMM DD, YYYY') }}</div>
                        <div class="box-border-bottom b f4-l f5 pa3-l pa3">Reference: {{ sale?.number || sale?.reference }}</div>
                    </div>
                    <div class="pa4-l pa2" style="background: #fbfcfe">
                        <div class="tc pa4" style="background: white">
                            <div class="flex justify-center items-center" v-if="business">
                                <div ref="companyLogoWrapper" v-if="business.imageUrl">
                                    <img ref="companyLogo" :src="business?.imageUrl" alt=""
                                        style="object-position: center; object-fit: cover; width: 70px; height: 70px;"
                                        class="rounded-circle" />
                                </div>
                                <div v-else class="flex justify-center items-center font-w3"
                                    style="background:rgb(215, 222, 250);height:50px;width:50px;border-radius:50%;font-size:25px;color:rgb(19, 44, 140);">
                                    <span class="org-initials">{{ getEntityInitials(business?.name || '') }}</span>
                                </div>
                            </div>
                            <div class="b pv3">{{ business?.name }}</div>
                            <div class="pv1" v-if="business?.phoneNumber">{{ business?.phoneNumber }}</div>
                            <div class="pv1" v-if="business?.orgEmail">{{ business?.orgEmail }}</div>
                            <div class="pv1" v-if="business?.address">{{ business?.address }}</div>

                            <div class="pt3">
                                <!-- <img :src="require('@/assets/images/Succes-Icons.svg')" alt="" /> -->
                                <div class="b tc">Parked</div>
                                <div class="pv1">Transaction Total</div>
                                <div class="pv2 f4 b">{{ formatAmount(sale.totalAmount, $store.state.Settings?.currency) }}</div>
                            </div>
                            <!-- $store.state.Settings?.currency -->
                            <div class="font-w1 f5 pt3">
                                Your <span>Order</span> <span class="pl1">ID: {{ sale?._id }}</span>
                            </div>
                            <div class="pv1 mt2">
                                Created: {{ moment(sale.createdAt) }}
                            </div>
                        </div>
                        <div style="background: white">
                            <div class="items-warapper pa5-l pa4 font-w1 pb3-l">
                                <div class="flex justify-between pv3 box-border-bottom color-ash">
                                    <div>Transaction Type</div>
                                    <div>POS</div>
                                </div>

                                <div v-for="item of sale?.items" v-bind:key="item._id">
                                    <div class="flex justify-between pv3 box-border-bottom ttc"
                                        style="border-top: 1px solid #e3e8ee">
                                        <div>
                                            <div>{{ item.name }} ({{ item.quantity }})</div>
                                            <div v-if="item.notes" class="text-muted">{{ item.notes }}</div>
                                        </div>
                                        <div>
                                            <div v-if="item.discount > 0">
                                                <span class="text-muted" style="text-decoration:line-through;">{{ formatAmount(item.quantity * item.oldPrice, $store.state.Settings?.currency) }}</span>&nbsp;
                                                <span>{{ formatAmount(item.total, $store.state.Settings?.currency) }}</span>
                                            </div>
                                            <div v-else>{{ formatAmount(item.total, $store.state.Settings?.currency) }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex justify-between pv3 box-border-bottom color-ashx">
                                    <div>Subtotal</div>
                                    <div>{{ formatAmount(sale?.subtotal, $store.state.Settings?.currency) }}</div>
                                </div>

                                <div v-if="item?.discount > 0" class="flex justify-between pv3 box-border-bottom color-ashx">
                                    <div class="ttc">
                                        <span class="pr1">Discount</span>
                                        <span v-if="item?.discountType === 'percentage'"> ({{ item?.discount }} %) </span>
                                        <span v-else> ({{ formatAmount(item?.discount, $store.state.Settings?.currency) }}) </span>
                                    </div>
                                    <div>{{ formatAmount(item?.totalAfterDiscount, $store.state.Settings?.currency) }}</div>
                                </div>
                                <div v-if="item?.tax > 0" class="flex justify-between pv3 box-border-bottom color-ash">
                                    <div class="ttc">
                                        <span class="pr1">Tax</span>
                                        <span> ({{ item?.tax }}) </span>
                                    </div>
                                    <div>{{ item?.totalAfterDiscount }}</div>
                                </div>

                                <div class="flex justify-between pv3 box-border-bottom f5 b">
                                    <div>Grand Total</div>
                                    <div>{{ formatAmount(sale.totalAmount, $store.state.Settings?.currency) }}</div>
                                </div>

                                <div v-if="sale?.customer" class="flex items-center justify-between pv3 box-border-bottom">
                                    <div>Billed to</div>
                                    <div class="w-50-l w-70 tr">
                                        <div class="font-w2 pb1">{{ sale.customer.name }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- column table -->
                            <div class="flex flex-column pa2-l pa2 pl5-l pr5-l pl2 pr2"
                                v-for="account of sale.glAccounts" v-bind:key="account">
                                <div class="flex justify-between pv2 box-border-bottom">
                                    <div style="text-transform: capitalize;">{{ account.glAccount }}</div>
                                    <div>{{ formatAmount(account?.glAmount, $store.state.Settings?.currency) }}</div>
                                </div>
                            </div>
                            <div class="flex items-center justify-center pv3 box-border-bottom">
                                <span class="mr1">Staff: </span>
                                <span class="ttc">{{ sale?.staffName }}</span>
                            </div>
                            <div v-if="state?.footnote" v-html="state.footnote" class="footnote ph4-l tc"></div>
                            <div class="flex items-center justify-center w-50-l w-80 center pb3 pt3">
                                <div class="b pr2" style="color: #132c8c">Powered by:</div>
                                <img :src="require('@/assets/images/simple-logo-mobile.svg')" alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-between items-center" style="border-top: 1px solid #e3e8ee">
                        <div class="action-btns ph3-l ph3 pv3 w-70">
                            <font-awesome-icon v-if="state.downloading" icon="fas fa-spinner" />
                            <!-- <a v-else href="#" @click.prevent="
                                    downloadReceipt({ reference: sale?.reference, orgId: orgData.orgId, userId: orgData.userId })
                                ">
                                <img :src="require('@/assets/images/direct-download.svg')" alt="" />
                            </a> -->
                            <!-- <a :href="'whatsapp://send?text=' + shareUrl" data-action="share/whatsapp/share"
                                class="share-btn-mobile">
                                <img :src="require('@/assets/images/share.svg')" class="ph3" alt="" />
                            </a> -->
                            <!-- <a href="" class="share-btn-desktop" @click.prevent="copyShareLink(shareUrl)">
                                <img :src="require('@/assets/images/share.svg')" class="ph3" alt="" />
                            </a> -->
                            <a href="#" @click.prevent="printReceipt">
                                <img :src="require('@/assets/images/printer.svg')" alt="" />
                            </a>
                        </div>

                        <template v-if="editable">
                            <div class="ph5-l ph4 pv4-l pb0-l pb4">
                                <button class="btn2">Edt</button>
                                <button class="ml2">Confirm</button>
                            </div>
                        </template>

                        <template v-else>
                            <div></div>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
    
</template>
<!-- eslint-disable -->
<script>
    /* eslint-disable */
    import { computed, reactive, ref, onMounted, watch } from 'vue'
    import { copyText } from 'vue3-clipboard'
    import { useStore } from 'vuex'
    import { useRoute, useRouter } from 'vue-router'
    import { jsPDF } from 'jspdf'
    import { formatAmount, formatDate, getEntityInitials } from '@/utils/lib'
    import AppWrapper from '@/layout/AppWrapper'
    import Spinner from '@/components/Spinner'
    import moment from 'moment'

    export default {
        name: 'PosReceipt',
        components: { Spinner, AppWrapper },
        
        setup(props) {
            const store = useStore()
            const route = useRoute()
            const router = useRouter()
            const sale = computed(() => store?.state?.Pos?.sale)
            const orgData = computed(() => store?.state?.Auth?.userData)
            const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
            const orgImage = computed(() => store?.state?.Auth?.orgImage)
            const userInitials = computed(() => store?.state?.Auth?.userInitials)
            const userData = computed(() => store.state?.Settings?.organization)
            const business = computed(() => store.state?.Settings?.business)
            const date = ref('')
            const formattedDate = computed(() => date.value.toLocaleDateString() + ' ' + date.value.toLocaleTimeString())

            const shareUrl = computed(
                () =>
                    `${process.env.VUE_APP_BASE_URL}/${store?.state?.Auth?.userData?.orgId}/${store?.state?.Auth?.userData?.userId}/transaction/${sale.value?.reference}/receipt`
            )

            const state = reactive({
                newPDF: null,
                canvas: null,
                height: null,
                bankName: '',
                bankAccountName: '',
                bankAccountNumber: '',
                sortCode: '',
                downloading: false,
                footnote: ''
            })

            const pdf = new jsPDF('p', 'mm', 'a4')
            pdf.setFontSize(40)

            const copyShareLink = url => {
                copyText(url, undefined, (error, event) => {
                    if (error) {
                        store.dispatch('Alert/setAlert', {
                            message: `Could not copy receipt link`,
                            status: false
                        })
                    } else {
                        store.dispatch('Alert/setAlert', {
                            message: `Receipt link copied`,
                            status: true
                        })
                    }
                })
            }

            const downloadReceipt = async data => {
                state.downloading = true
                store.dispatch('Transaction/getTransactionReceiptPdf', data).then(response => {
                    if (response?.data?.error) {
                        console.error(response.data.error)
                    }

                    const date = formatDate(new Date(), 'DDMMYYYY')

                    const fileURL = window.URL.createObjectURL(new Blob([response]))
                    const fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    const fileName = `${orgData.value?.org} - ${date}`
                    fileLink.setAttribute('download', `pos-${data.reference} - ${fileName}.pdf`)
                    fileLink.setAttribute('target', '_blank')
                    document.body.appendChild(fileLink)
                    fileLink.click()
                    fileLink.remove()
                    state.downloading = false
                })
            }

            watch(
                () => userData.value,
                (newVal, oldVal) => {
                    if (newVal !== oldVal && newVal?.bankDetails) {
                        const bankDetails = newVal?.bankDetails.find(bank => bank.isDefault === 'true' || bank.isDefault === true)
                        state.bankName = bankDetails?.bankName
                        state.bankAccountNumber = bankDetails?.bankAccountNumber
                        state.bankAccountName = bankDetails?.bankAccountName
                        state.sortCode = bankDetails?.sortCode
                    }
                }
            )

            const changeBusinessType = type => {
                store.dispatch('DashboardStats/getBusinessType', type)
            }

            onMounted(() => {
                const id = route.params.id
                const business = route.query.business
                store.dispatch('Settings/getBusiness', business)
                store.dispatch('Settings/getOrganization')
                store.dispatch('Settings/getBankAccounts')
                store.dispatch('Pos/getSaleHistory', { id, business })
                store.dispatch('Settings/getAccountSettings').then(data => {
                    if (data.status) {
                        state.footnote = data?.data?.footnote || ''
                    }
                })
            })

            return {
                state,
                sale,
                orgData,
                orgInitials,
                userInitials,
                orgImage,
                business,
                shareUrl,
                copyShareLink,
                downloadReceipt,
                formatDate,
                formatAmount,
                changeBusinessType,
                date,
                formattedDate,
                getEntityInitials,
                moment,
            }
        },

    methods: {
        printReceipt() {
            this.$htmlToPaper('receiptContainer')
        }
    }
}
</script>

<style>
    .share-btn-mobile {
        display: none;
    }

    .share-btn-desktop {
        display: inline;
    }

    @media screen and (max-width: 1261px) {
        .share-btn-mobile {
            display: inline;
        }

        .share-btn-desktop {
            display: none;
        }
    }

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }

    .fa-spinner {
        animation: spinner 1s linear infinite;
    }
</style>